.season{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .progress-chip{
        background-color: #547837;
        border-radius: 8px 0 12px 0;
        width: 13%;
        height: 22%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: white;
        font-size: 1em;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-family: 'Goblin One', cursive;
        .progress{
            border-bottom: solid;
        }
    }
    @media (max-width: 320px) {
        .progress-chip{
            font-size: 0.5em;
        }
    }
    @media (min-width: 320px) {
        .progress-chip{
            font-size: 0.6em;
        }
    }
    @media (min-width: 992px) {
        .progress-chip{
            font-size: 1em;
        }
    }
}

.season.Spring{
    background-image: url("/assets/seasons/spring.png");
    .progress-chip{
        //background-image: url("/assets/seasons/spring-chip.png");
    }
}
.season.Summer{
    background-image: url("/assets/seasons/summer.png");
    .progress-chip{
        //background-image: url("/assets/seasons/spring-chip.png");
    }
}
.season.Fall{
    background-image: url("/assets/seasons/fall.png");
    .progress-chip{
        //background-image: url("/assets/seasons/spring-chip.png");
    }
}
.season.Winter{
    background-image: url("/assets/seasons/winter.png");
    .progress-chip{
        //background-image: url("/assets/seasons/spring-chip.png");
    }
}