.content {
  position: relative;
  height: 70vh;
  border:4px solid #96773B;
  font-family: 'Goblin One', cursive;
  color: #96773B;
  text-align: center;
  border-radius: 25px;
  background-image: url("/assets/parchementBackground.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h2 {
    border-bottom: #96773B 2px solid;
    margin:0;
  }
  .ready-button{
    margin-bottom: 3vmin;

  }

  .player-list {
    margin-top: 1vmin;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-start;
    align-content: center;
    flex-direction: column;
    height: 100%;
    .player-item {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .player-name {
        font-weight: bold;
      }

      .player-status {
        color: #888;
      }


    }
  }
}
