.rules{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 15vh;
    width: 90%;
    //padding: 5vmin;
    .rule{
        position: relative;
        margin:1vw;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border-radius: 8px;
        img{
            border-radius: 8px;
            height: 100%;
            object-fit: contain;
            box-shadow: 2px 4px 9px 3px black;
        }
        .rule-icon{
            position: absolute;
            top: 0;
            right: 0;
            color: white;
        }
    }

    .focussed-container{
        position:absolute;
        height: 100vh;
        width:100vw;
        top:0;
        background-color: #282c3455;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        img{
            height: 90%;
            width: 90%;
            object-fit: contain;
        }
    }
    @for $i from 26 through 41 {
        .rule-#{$i} {
            background-image: url("/assets/rules/#{$i}.jpg");
        }
    }
}
@media (max-width: 320px) {
    .rules{

        height: 13vh;
    }
}