.game-container {
  overflow-y: clip;
}

.game-over-container {
  button {
    background-image: url("/assets/parchementBackground.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .leave-button {
    font-size: 4vmin;
    margin: 1vh;
  }

  margin: 1vmin;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  height: 98vh;
  width: 100%;

  .grid-row {
    display: grid;
    grid-template-columns: 1fr 14fr 1fr;
  }

  .card-row {
    display: grid;
    grid-template-columns: 1fr 7fr 7fr 1fr;
    grid-gap: 1vmin;
    padding: 1vmin;
    width: 100%;
    min-height: 116px;
    margin-top: 2vmin;
  }

  .player-list {
    overflow-y: scroll;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;

    .first-player, .other-player {
      margin: 1vmin
    }

    overflow: auto;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;


  }


}

.player-final {
  background-image: url("/assets/parchementBackground.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 12px;
  font-family: 'Goblin One', cursive;
  padding: 1vmin;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #96773B;

  .header {
    padding: 4vmin;
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    text-align: center;

    justify-content: center;
    align-items: center;
    color: #96773B;

    .player-name {
      align-items: center;
    }

    .player-score {

    }
  }

  .player-rank {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7vmin;
    height: 7vmin;
    border-radius: 32px;
    font-size: 1em;
    color: white;
    background-color: #076700;
  }
}

.first-player {
  width: 80%;

  .grid-row {
    width: 100%;
  }

  .map-display, .base-grid, .overlay-grid {
    height: 50vmin;
    width: 50vmin;
  }

  .scores {
    div {
      min-width: 1.5em;
      min-height: 0.7em;
      font-size: 0.8em;
    }
  }
}

.other-players {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;

}

.other-player {
  width: 30vw;

  .map-display, .base-grid, .overlay-grid {
    height: 20vmin;
    width: 20vmin;
  }

  .scores {
    div {
      min-width: 1em;
      min-height: 0.7em;
      font-size: 0.6em;
    }
  }

  .header {
    padding: 2vmin;
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    text-align: center;
    font-size: 0.5em;
    justify-content: center;
    align-items: center;
    color: #96773B;

    .player-name {
      align-items: center;
    }

    .player-score {

    }

    .player-rank {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3vmin;
      height: 3vmin;
      border-radius: 32px;
      font-size: 0.5em;
      color: white;
      background-color: #076700;
    }
  }

}

@media (min-width: 768px) {
  .grid-row {
    display: grid;
    grid-template-columns: 1fr 14fr 1fr;
  }
  .first-player {
    width: 75%;

  }
  .game-over-container {
    width: 75vw;
  }
;
}

@media (min-width: 992px) {


  .game-over-container {
    width: 60vw;
  }
;
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {


}
