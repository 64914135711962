.map-display {
  position: relative;
  display: grid;
  background-image: url("/assets/board.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

}

.map-display, .base-grid, .overlay-grid, .attacking {
  width: 36vmax;
  height: 36vmax;
  padding: 1.2vmin;
  margin: auto;
}

.attacking {
  top: 0;
  left: 0;
  border: 2vmin solid #380F53;
  margin: 0;
  padding: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;

  .target {
    position: relative;
    top: -1.4em;
    width: fit-content;
    padding: 1vmin;
    font-size: 0.8em;
    z-index: 50;
    background-color: #380F53;
    border-radius: 2vmin;
    color: white;
    font-family: 'Goblin One', cursive;

  }
}

.base-grid, .overlay-grid {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: repeat(11, 1fr);

}

.map-row {
  display: contents; // This allows the tiles to be direct grid children
}

.map-preview .map-tile {
  border: none;
}

@keyframes backgroundChange {
  0% {
    background-size: 0
  }
  100% {
    background-size: 100%
  }
}


.map-display {
  .map-tile, .overlay-tile {
    width: 100%;
    height: 100%;
  }

  .base-grid {
    .village, .forest, .field, .monster, .ocean {
      animation: backgroundChange 0.3s;

    }
  }

}

.overlay-grid {
  .overlay-tile.filled {
    box-shadow: 0 0 10px 4px white;
  }
}

@media (min-aspect-ratio: 1/1) {
  .map-display, .base-grid, .overlay-grid, .attacking {
    width: 32vmin;
    height: 32vmin;
  }
}

@media (max-width: 320px) {
  .map-display, .base-grid, .overlay-grid, .attacking {
    width: 32vh;
    height: 32vh;
    padding: 1.2vmin;
    margin: auto;
  }
  .attacking {
    padding: unset;
  }
}

/* Smaller devices (landscape phones, 576px and up) */
@media (min-width: 576px) {


}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {


}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}

