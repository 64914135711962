.scores{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}
.score.done{
  filter:none;
  color:black;
}
.score.active{
  color:black;
  //box-shadow: 0px 1px 4px 3px rgba(255, 255, 255, 0.78);
  border:2px solid rgb(255,191,65);
  filter: none;
  div{
    border-width: 2px;
  }
}
.score{
  color:#96773B;
  filter: grayscale(1) opacity(0.8)
}
.score{
  background-image: url("/assets/parchementBackground.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 12px;
  margin: 1vmin;
  display: grid;
  grid-template-areas:
    "r1 r2 t"
    "c m t";
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  width:25%;

  .r1{
    grid-area: r1;
    border-radius:  12px 0 0 0;
    
  }
  .r2{
    grid-area: r2;
  }
  .coins{
    grid-area: c;
    border-radius: 0 0 0 12px;

  }
  .monsters{
    grid-area: m;
  }
  .total{
    grid-area: t;
    border-radius: 0 12px 12px 0;
    align-items: center;
  }
  div{

    display: flex;
    font-family: "Asar", serif;
    font-size: 1em;
    text-align: center;
    border: 0.5px solid #39648A;
    font-weight: bold;
    justify-content: center;
  }
}
.player-scores{
  width:90%
}