.player-status{
  display: flex;
  flex-direction: row;
  margin:1vmin;
  border-radius: 0.5vmin;
  color:white;
  align-items: center;
  .player-name{
    font-family: 'Goblin One', cursive;
    color:#96773B;
    text-align: center;
  }
  .player-name.current-player{
    color: white;
    background-color: #96773B;
    padding:1vmin;
    border-radius: 10px;
  }
}
.player-statuses{
  background-image: url("/assets/parchementBackground.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-wrap:wrap ;
  width: 90%;
  margin:1vmin;
  border-radius: 1vmin;
  border:solid #39648A;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 0.5em;
}