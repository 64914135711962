body {


  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root{
  width: 100vw;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
button {
  padding: 0.5rem 1rem;
  border: 2px solid #96773B;
  border-radius: 4px;
  background-color: transparent;
  color: #96773B;
  font-family: 'Goblin One', cursive;
;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #96773B;
    color: white;

  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}
.container{
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  height: 95vh;
  width: 100vw;
  background-image: url("/assets/background.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  overflow-y: scroll;
  justify-content: center;
  .content{
    width: 80vw;
    height: 80vh;
    background-image: url("/assets/parchementBackground.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border:5px solid #96773B;
    border-radius: 16px;
    font-family: 'Goblin One', cursive;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  // Small devices (landscape phones, 576px and up)
  @media (max-width: 576px) {
    .content .game-list .game-item{
      flex-direction: row;
    }
    .game-name{
      overflow-x: scroll;

    }
  }
  @media (min-width: 576px) {


  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {

  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    .content{
      max-width: 50vw;

    }
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {

  }
}
button.back{
  position:absolute;
  border:none;
  margin-left: auto;
  top:0;
  left: 0;
}
button.back:hover{
  background-color: transparent;
  color:#96773B;
  text-decoration: underline;
}
button.icon{
  border-radius: 32px;
  height: 24px;
  width: 16px;
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
