.create{
    height: fit-content !important;
    position: relative;
    padding: 1em;
    color:#96773B;
    text-align: center;
    .create-button{
        width: 100%;
        margin-bottom: 1vmin;
    }
    input{
        margin-top: 1em;
        margin-bottom: 1em;
        font-size: 1em;
        font-family: 'Goblin One', cursive;
        color:#96773B;
        text-align: center;
    }
}