
.map-tile {
    width: 7vmin;
    height: 7vmin;
    border: 1px dashed #996d00d6;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.map-tile.mountain {
    background-image: url("/assets/tiles/mountain.png");
}

.map-tile.forest {
    transform: scale(1.33);
    background-image: url("/assets/tiles/forest.png");
    border: none;}

.map-tile.field {
    transform: scale(1.33);
    background-image: url("/assets/tiles/field.png");
    border: none;
}


.map-tile.ocean {
    transform: scale(1.33);
    background-image: url("/assets/tiles/ocean.png");
    border: none;
}

.map-tile.village {
    transform: scale(1.33);
    background-image: url("/assets/tiles/village.png");
    border: none;
}
.map-tile.monster {
    transform: scale(1.33);
    background-image: url("/assets/tiles/monster.png");
    border: none;
}

.map-tile.ruin {
    background-image: url("/assets/tiles/ruins.png");
    transform: scale(0.9);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: none;
}

/* Smaller devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .map-tile {
        width: 5vmin;
        height: 5vmin;
    }

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .map-tile {
        width: 3vmin;
        height: 3vmin;
    }

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .map-tile {
        width: 5vmin;
        height: 5vmin;
    }

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .map-tile {
        width: 5vmin;
        height: 5vmin;
    }

}