.game-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  height: 95vh;
  width: 100vw;
  justify-content: space-evenly;

  .game-content {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
  }

  .grid-row {
    display: flex;
    justify-content: center;
  }

  .card-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1vmin;
    padding: 1vmin;
    width: 95%;
    min-height: 90px;
    margin-top: 2vmin;
  }

  background-image: url("/assets/background.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  overflow-y: scroll;
  @media (max-aspect-ratio: 10/8) {
    .game-over-container {
      .player-list {
        background: linear-gradient(#4b4b4b 33%, rgba(48, 193, 106, 0)),
        linear-gradient(rgba(48, 193, 106, 0), #4b4b4b 66%) 0 100%,
        radial-gradient(farthest-side at 50% 0, rgba(34, 34, 34, 0.5), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 50% 100%, rgba(34, 34, 34, 0.5), rgba(0, 0, 0, 0)) 0 100%;

        background-repeat: no-repeat;
        background-attachment: local, local, scroll, scroll;
        background-size: 100% 45px, 100% 45px, 100% 15px, 100% 15px;
      }
    }
    @media (max-width: 320px) {
      .card-row {
        width: 32vh;
      }
    }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
      .grid-row {
        display: flex;
      }
      .card-row {
        //height:15vh
      }
      .game-content, .card-row, .grid-row {
        width: 50vmax;
      }
    ;
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
      .game-content, .card-row, .grid-row {
        width: 50vmax;
        max-width: none;
      }
      .card-row {
        //height:15vh
      }

    }

    // Extra large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {
      .game-content {
        width: 60vw;
      }


    }

    @media ((min-width: 420px)) {
      .game-content {
        max-width: 420px;
      }
      .game-over-container {
        font-size: 3vmin;

        .map-display, .base-grid, .overlay-grid {
          height: 45vmin;
          width: 45vmin;
        }

        .other-player {
          .player-rank {
            font-size: 1.5vmin;
          }

          .grid-row {
            width: 100%;
          }

          .map-display, .base-grid, .overlay-grid {
            height: 20vmin;
            width: 20vmin;
          }
        }
      }
    }
  }

  @media (min-aspect-ratio: 10/8) {
    .game-content {
      width: 90vw;
      display: grid;
      align-items: start;
      justify-items: center;
      grid-template-rows: repeat(6, 1fr);
      grid-template-areas:
          "s s s s s p p p"
          "g g g g g c c c"
          "g g g g g r r r"
          "g g g g g r r r"
          "g g g g g r r r"
          "g g g g g r r r";

      .player-statuses {
        grid-area: p;
      }

      .player-scores {
        grid-area: s;
        font-size: 3vh;
        align-content: center;
        width: 70vh;

        .score div {
          align-items: center;
        }
      }

      .map-display, .base-grid, .overlay-grid, .attacking {
        width: 65vh;
        height: 65vh;
      }

      .grid-row {
        grid-area: g;
        display: grid;
        grid-template-areas:
                "c g 3"
                "1 t 2";

        .coin {
          grid-area: c;
          width: 5.3vh;
          height: 5.3vh;
        }

        .control-container {
          grid-area: t;
          bottom: -10.6vh;
          right: unset;
          height: fit-content;
          width: 100%;
          margin-bottom: unset;
          margin-left: unset;

          .control {
            flex-direction: row;
            font-size: 5.3vh;
            border-radius: 0 0 16px 16px;
          }
        }
      }

      .card-row {
        grid-area: c;
        //grid-template-rows: 1fr 1fr;
        //grid-template-columns: 1fr;
        width: 100%;
        height: 20vh;

        .current-card {
          grid-column: 1;
          grid-row: 1;
        }

        .season {
          grid-column: 2;
          grid-row: 1;

        }
      }

      .rules {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-area: r;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        height: 100%;
        width: 100%;

        .focussed-container {
          left: 0;

          img {
            height: 80vh;
          }
        }

        .rule {
          height: 25vh;

          img {
            height: 100%;
          }
        }
      }


    }
    .game-over-container {

      width: 100vw;
      display: grid;
      grid-template-areas:
        "r p p p p p p"
        "r p p p p p p"
        "r p p p p p p"
        "r l l l l l l";
      justify-content: center;
      justify-items: center;

      .player-list {
        grid-area: p;
        width: 90%;
        height: 85vh;
        flex-direction: row;
      }

      .other-player {
        width: 20vw
      }

      .other-players {
        height: 90%;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-evenly;
        overflow: scroll;

        background: linear-gradient(#4b4b4b00 33%, rgba(48, 193, 106, 0)), linear-gradient(rgba(48, 193, 106, 0), #4b4b4b00 66%) 0 100%,
        radial-gradient(farthest-side at 50% 0, rgba(34, 34, 34, 0.5), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 50% 100%, rgba(34, 34, 34, 0.5), rgba(0, 0, 0, 0)) 0 100%;

        background-repeat: no-repeat;
        background-attachment: local, local, scroll, scroll;
        background-size: 100% 45px, 100% 45px, 100% 15px, 100% 15px;
      }

      .first-player {
        width: 44.2%;

        .header {
          font-size: 2.5vmin;

          .player-rank {
            width: 5vmin;
            height: 5vmin;
          }

          .player-name {
            overflow-wrap: anywhere;
          }
        }

        .map-display, .base-grid, .overlay-grid, .attacking {
          width: 30vmin;
          height: 30vmin;
        }

        .score {

          background-image: none;
          font-size: 2vmin;
        }
      }

      .leave-button {
        grid-area: l;
      }

      .rules {
        display: grid;
        grid-template-columns: 1fr;
        grid-area: r;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        height: 90%;
        width: 100%;

        .focussed-container {
          left: 0;

          img {
            height: 80vh;
          }
        }

        .rule {
          height: 20vh;

          img {
            height: 100%;
          }
        }
      }
    }

  }

}
