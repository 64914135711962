.current-card {
  display: grid;
  grid-template-rows: 1fr 3fr;
  background-image: url("/assets/cards/red-card-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border-bottom: none;
  position: relative;
  font-size: 1.2vh;

  .card-header {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .map-tile {
      width: 30%;
    }
  }

  .ruin {
    z-index: 1;
    //position: absolute;
    //top: 1vmin;
    //left: 1vmin;
    background-color: #96773B;
    border-radius: 2vmin;
  }

  .card-name {
    z-index: 2;
    color: white;
    font-family: 'Goblin One', cursive;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
  }

  .card-name.disabled {
    filter: contrast(0.2);
  }

  .shape-grid {
    display: grid;
    margin-bottom: 10px;
    background-color: transparent;
  }

  .map-tile {
    border: none;
  }

  .card-value {
    color: white;
    font-family: 'Goblin One', cursive;
    //position: absolute;
    //right: 0.2em;
    //top:0.2em;
    height: 100%;
    font-size: 3vmin;
    border-radius: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("/assets/seasons/spring-chip.png");
    padding-left: 0.4em;
  }

  .coin .shape-grid {
    background-color: #ffd700;
  }

  .ruin .shape-grid {
    background-color: #808080;
  }

  .shapes {
    .draggable-shape{
      aspect-ratio: 1/1;
    }
    .draggable-shape:after {

      content: "";
      display: block;
      padding-bottom: 100%;
      //padding-right: 100%;
    }

    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;

    .draggable-tile {
      width: 100%;
      height: 100%;
    }

    .map-tile {
      height: 100%;
      width: 100%;
    }
  }

  .shapes-5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .shapes.disabled {
    pointer-events: none;

    .map-tile {
      filter: contrast(0.6);
    }
  }

}

.current-card.disabled {
  filter: contrast(0.5);
}

//@media (max-width: 320px) {
//  .current-card{
//    height: 12.8vh;
//    width: 19vh;
//  }
//}
//@media (min-width: 992px) {
//
//.current-card{
//  width: 26vh;
//  height: 18vh;
//}
//}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {


}
