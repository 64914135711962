.navbar{
  height: 5vh;
  background-color: #39648A;
  color: #ffbf41;
  font-family: 'Goblin One', cursive;
  padding-left: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .game-title{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
  .username{
    max-width: 25vw;
    overflow-wrap: anywhere;
    font-size: small;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  button.login{
    border: none;
    background-color: rgb(23 44 62 / 65%);
    padding: 0.6em;
    border-radius: 32px;
    height: fit-content;
    margin-right: 2vw;
  }

  .logout{
    margin-left: 2vw;
    margin-right: 2vw;
    border:none;
    padding:0;
    height:fit-content;
    text-decoration: underline;
  }
}