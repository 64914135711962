.login {
  color: #96773B;
  display: flex;
  flex-direction: column;
  height: fit-content !important;

  .name-input {
    display: flex;
    flex-direction: column;
    padding-bottom: 1em;
  }

  input {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 1em;
    font-family: 'Goblin One', cursive;
    color: #96773B;
    text-align: center;
  }
  .loading-overlay{
    top:0;
    bottom: 0;
    position:absolute;
    background-color: #ffffff82;
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height:100%;
  }
}