
.control-container{
  position: absolute;
  top: 0;
  right: -2em;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .control{
    display: flex;
    flex-direction: column;

    background-image: url("/assets/parchementBackground.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 0 16px 16px 0;
    height: fit-content;

  }

  margin-top: auto;
  margin-bottom: auto;
  margin-left: -2.5vmin;
  color: white;
  .btn{
    margin:0.5em;
    min-width: 16px;
    min-height:16px;
  }
  .btn.validate{
    color: #076700;
  }
  .btn.cancel{
    color: #8f0000;
  }
  .btn.disabled{
    color: #858585
  }
}
