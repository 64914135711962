.container {
  .content {
    padding-bottom: 1em;
    color: #96773B;

    h4 {
      margin-bottom: 0;
    }
    .deleting{
      filter: opacity(0.5);
    }
    .profile {
      height: fit-content;
      display: flex;
    }

    .filter-selector {
      display: flex;

      padding: 0 1vmin;
      color: white;

      cursor:pointer;

      .left {
        background-color: #cdb587;
        border-radius: 16px 0 0 16px;
      }

      .right {
        background-color: #cdb587;
        border-radius: 0 16px 16px 0;

      }
      .active {
        transition-property: background-color;
        transition-duration: 0.3s;
        background-color: #96773b;
      }

      h4 {
        margin:0;
        padding: 0.4vmin 0.5vmin;
      }
    }

    .game-list {
      height: 45vh;
      overflow-y: scroll;
      border-top: solid 2px;
      border-bottom: solid 2px;
      margin-bottom: 1em;
      width: 100%;

      .game-item {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0.2vmin 0;
        //margin-top: 1vh;
        //        margin-bottom: 1vh;
        .game-name {
          width: 100%;
        }

        .game-status {
          color: white;
          background-color: #96773b;
          border-radius: 16px;
          width: fit-content;
          padding: 0.2vmin 0.7vmin;
          font-size: 0.5em;;
        }

        button {
          width: 20vw;
        }

      }
    }

    .create-game {
      margin-bottom: 1vmin;
    }
  }

  input {
    background-color: #ffffff5c;
    border: none;
    border-bottom: 1px solid;
    border-radius: 4px 4px 0 0;
    text-align: right;
    height: fit-content;
  }

  // Small devices (landscape phones, 576px and up)
  @media (max-width: 576px) {
    .content .game-list .game-item {
      flex-direction: row;
    }
    .game-name {
      overflow-x: scroll;

    }
  }
  @media (min-width: 576px) {
    .content .game-list .game-item {
      flex-direction: row;
    }

  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    .content .game-list .game-item {
      flex-direction: row;
    }
    .game-name {
      text-align: left;
      align-self: center;
    }
    .game-item {
      border-bottom: 0.4px solid #96773B;
    }
    .game-item:hover {
      background-color: rgba(255, 255, 255, 0.56);
    }
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    .content .game-list .game-item {
      flex-direction: row;
    }
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    .content .game-list .game-item {
      flex-direction: row;
    }
  }
}
