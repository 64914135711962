.user-tab {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  .user-menu {
    display: none;
  }
  .user-menu-overlay{
    width: 100%;
    height:100vh;
    background-color: rgba(75, 75, 75, 0.59);
    position:fixed;
    top:5vh;
  }
  .user-menu.active {
    display: flex;
    position: fixed;
    right: 0;
    top: 6vh;
    z-index: 100000000;
    background-color: #39648a;
    border-radius: 8px;
    box-shadow: 2px 2px 9px 4px #5a5a5a;
    padding: 1vh;
    color: white;
    flex-direction: column;
    .logout{
      color:#ffbf41;
    }
  }

  .round-button {
    color: #ffbf41;
    border: none;
    border-radius: 16px;
    background-color: #39648A;
  }
}