.coin-column{
  width:100%;
  height: 100%;
  display:grid;
  .coin{
    background-image: url("/assets/coin.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-position-x: 100%;
  }
  .disabled{
    filter: contrast(0.5);
  }
}